/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// Global CSS
*,
body,
html {
  font-family: "Montserrat", sans-serif;
}
.h1 {
  font-size: 45px;
  letter-spacing: 0.03em;
  margin: 0 0 40px;
}
.h2 {
  font-size: 25px;
  letter-spacing: 0.03em;
  margin: 0 0 20px;
}
.h3 {
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1.2;
  margin: 0;
}
.h4 {
  letter-spacing: 0.03em;
  font-size: 25px;
  font-weight: bold;
}
.text1 {
  color: #fff;
  font-size: 22px;
}
.text2 {
  font-size: 14px;
  margin: 20px 0 0;
  color: #fff;
  letter-spacing: 0.05em;
}
.text3 {
  font-size: 18px;
  letter-spacing: 0.015em;
}
.text4 {
  font-size: 18px;
  letter-spacing: 0.015em;
  margin: 20px 0 40px;
}
.text5 {
  color: #92969f;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 40px;
  letter-spacing: 0.025em;
}
.text6 {
  margin: 30px 0;
  color: #9499a9;
  letter-spacing: 0.05em;
  font-size: 14px;
}
.color-white {
  color: #fff;
}
.color-black {
  color: #374859;
}
.f-w-300 {
  font-weight: 300;
}
.f-w-400 {
  font-weight: 400;
}
.f-w-500 {
  font-weight: 500;
}
.f-w-600 {
  font-weight: 600;
}
.f-w-700 {
  font-weight: 700;
}
.f-w-800 {
  font-weight: 800;
}
.f-w-900 {
  font-weight: 900;
}
.bg-transparent {
  background: transparent !important;
}
.bg-grey {
  background-color: #ebeef1;
}
.text-grey {
  color: grey;
}
.page-header {
  padding: 18px 0;
}
.page-title {
  margin: 0;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: #5178a0;
}
.button-inner ion-icon {
  color: #6f7483;
}
.relative {
  position: relative;
}
ion-content.bg-grey {
  --background: "#ebeef1";
}
.input {
  display: block;
  border-radius: 6px;
  background: none;
  padding: 13px 10px 15px 20px;
  font-size: 16px;
  margin: 0;
  font-family: Montserrat, sans-serif;
  color: #374859;
  font-weight: 500;
  width: 100%;
  max-width: 100%;
  -o-transition: border-color 0.1s ease-in-out;
  transition: border-color 0.1s ease-in-out;
  letter-spacing: 0.03em;
  background-color: #fff;
  box-shadow: 0 6px 10px 0 rgb(202 215 223 / 40%);
  border: 1px solid #fff;
  cursor: text;
  -webkit-transition: border-color 0.1s ease-in-out;
  -o-transition: border-color 0.1s ease-out;
  transition: border-color 0.1s ease-out;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  -moz-transition: border-color 0.1s ease-in-out;
  -ms-transition: border-color 0.1s ease-in-out;
}
.input:focus {
  border-color: #6751c8;
  outline: none;
}
.input.bg-purple {
  background: #dfdff0;
  border-color: #dfdff0;
  color: #795fec;
}
.label {
  display: block;
  text-transform: uppercase;
  font-size: 10px;
  color: #989ca5;
  font-weight: 700;
  letter-spacing: 0.15em;
  margin: 0 0 7px;
}
.select {
  border-radius: 6px;
  background: none;
  padding: 13px 10px 15px 20px;
  font-size: 16px;
  margin: 0;
  font-family: Montserrat, sans-serif;
  color: #374859;
  font-weight: 500;
  width: 100%;
  max-width: 100%;
  -o-transition: border-color 0.1s ease-in-out;
  transition: border-color 0.1s ease-in-out;
  letter-spacing: 0.03em;
  background-color: #fff;
  box-shadow: 0 6px 10px 0 rgb(202 215 223 / 40%);
  border: 1px solid #fff;
}
ion-button {
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.15em;
}
ion-button ion-icon {
  font-weight: 900;
  margin-right: 4px;
}
.bg-gradiant {
  background: linear-gradient(90deg, #271673 0, #523bb8);
}
.round-btn {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.d-inline-block {
  display: inline-block;
}
.d-block {
  display: block;
}
.pointer {
  cursor: pointer;
}
input.is-invalid,
textarea.is-invalid,
select.is-invalid {
  border-color: red;
}
.invalid-response {
  font-size: 12px;
  color: red;
}
// **********************************************************************
// Header CSS
.header {
  position: absolute;
  width: 95%;
}
.header::after {
  background-image: none;
}
.header-title {
  vertical-align: middle;
  font-size: 28px;
  letter-spacing: 0.03em;
  font-weight: 500;
  color: #fff;
  margin: 40px 0 0 10px;
}
.header-title2 {
  vertical-align: middle;
  font-size: 22px;
  letter-spacing: 0.03em;
  font-weight: 500;
  color: #fff;
  margin: 20px 0 20px;

  &.no-margin {
    margin: 0;
  }
}
.fab-btn {
  margin: 20px 20px 0 0;
}
.logo {
  width: 35px;
  height: 35px;
  vertical-align: middle;
  display: inline-block;
}

// Footer CSS
footer {
  padding: 0 30px 300px;
  position: relative;
}
.footer__diamond-grey {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  z-index: -2;
  background-image: url("/assets/images/diamond-grey-footer-aoneahsan.svg");
  background-position: 0 100%;
  background-size: auto 75%;
}
.footer__diamond-blue {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  z-index: -1;
  background-image: url("/assets/images/diamond-blue-border-aoneahsan.svg");
  background-position: right -5px bottom -5px;
  background-size: auto 40%;
}

// Home Page CSS
.hero-bg-1 {
  position: absolute;
  z-index: -1;
  background-image: url("/assets/images/diamond-gradient-aoneahsan.svg");
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: 98% 98%;
  background-position: 0 0;
  min-height: 600px;
  background-repeat: no-repeat;
}

.hero-bg-2 {
  position: absolute;
  z-index: -1;
  background-image: url("/assets/images/diamond-white-border-aoneahsan.svg");
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: 50% auto;
  background-position: right -3px top -3px;
  min-height: 600px;
  background-repeat: no-repeat;
}
.phone {
  width: 360px;
  height: 680px;
  margin: 20px auto 0;
}
.content {
  margin-top: 6rem;
}
.anonymous-img {
  width: 38px;
  height: 41px;
  display: block;
}
.section2 {
  padding: 180px 0;
  position: relative;
}
.section2-bg {
  position: absolute;
  z-index: -2;
  background-image: url("/assets/images/diamond-grey-aoneahsan.svg");
  top: -75%;
  left: 0;
  right: 0;
  bottom: -125%;
  background-size: auto 100%;
  background-position: 100%;
  background-repeat: no-repeat;
}
.section2-left {
  padding-left: 10%;
}
.section3 {
  padding: 0 20px;
}
.coin-icons {
  display: flex;
  justify-content: space-between;
  margin: 0px 0 60px 0;
}
.coin-img {
  width: 52px;
  height: 52px;
  display: inline-block;
}
.supported-coins {
  background-image: url("/assets/images/diamond-purple-aoneahsan.svg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: auto;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 380px;
  height: 380px;
  margin: -20px 40px -50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.section4 {
  padding: 230px 30px;
}
.section4-content {
  border: 3px solid rgba(55, 73, 90, 0.15);
  padding: 88px 20px;
  border-radius: 26px;
  -webkit-border-radius: 26px;
  -moz-border-radius: 26px;
  -ms-border-radius: 26px;
  -o-border-radius: 26px;
}
ion-toolbar {
  --background: transparent;
  --ion-color-base: transparent !important;
}

// @import "./dashboard.scss";
// ***********************************************************************
// dashboard component css
.p-ml {
  margin-left: 30px;
}
.dropdown-toggle::after {
  display: none;
}
.custom-modal {
  min-width: 340px;
  min-height: 380px;
  // overflow: scroll;
}
.custom-modal.w-h-auto {
  min-width: 320px;
  min-height: 300px;
}
.custom-modal .modal-header {
  padding: 20px 0;
}
.custom-modal .modal-title {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: #5178a0;
}
.modal-content {
  padding: 20px 40px;
  border: none;
}
.custom-modal.w-h-auto .modal-content {
  padding: 20px 0px;
}
.username-input {
  position: relative;
}
.save-username {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 20px;
  text-align: center;
  font-size: 18px;
  padding: 0 14px;
  cursor: pointer;
}
.save-username.disabled {
  color: #5178a0;
}
.portfolio-item-name,
.add-new {
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  position: relative;
  border-radius: 100px;
  transition: background-color 0.1s ease-out;
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -ms-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
}
.add-new {
  background-color: #e7f3fd;
  color: #60acf3;
  width: 20px;
  height: 20px;
  padding: 8px;
}
.portfolio-item-name.active::after {
  content: "";
  display: block;
  width: 11px;
  height: 11px;
  position: absolute;
  border: 2px solid #fff;
  top: -1px;
  right: -1px;
  background-color: #3cd483;
  border-radius: 100px;
}

// Dashboard.scss file css
.d-section1 {
  height: 94vh;
  min-height: 450px;
  background: linear-gradient(90deg, #271673 0, #523bb8);
  display: inline-block;
  width: 100%;
}
.d-section1.bg-white {
  background: white;
}
.d-section2 {
  padding-bottom: 50px;
}
.d-section1-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.d-section1-deatils {
  padding: 20px 0 40px 0;
}
.username {
  color: #a79ae1;
  font-size: 16px;
  letter-spacing: 0.03em;
  margin: 0 0 30px;
}
.networth {
  color: #a79ae1;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 0.15em;
  margin: 0 0 5px;
}
.networth-value {
  font-size: 35px;
  margin: 0 0 5px;
}
.currency {
  color: #9a8cd6;
  margin: 0 0.2em 0 0;
  display: inline-block;
}
.input2-con {
  width: 100%;
  position: relative;
}
.input2-con ion-icon {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 20px;
  height: 20px;
  padding: 10px 6px;
  color: #374859;
}
.input2-con .input2 {
  display: block;
  background: none;
  padding: 13px 10px 5px 40px;
  font-size: 16px;
  color: #374859;
  border: 0;
  border-bottom: 1px solid #d6dbe2;
  width: 100%;
  max-width: 100%;
  letter-spacing: 0.03em;
  transition: border-color 0.1s ease-in-out;
  -webkit-transition: border-color 0.1s ease-in-out;
  -moz-transition: border-color 0.1s ease-in-out;
  -ms-transition: border-color 0.1s ease-in-out;
  -o-transition: border-color 0.1s ease-in-out;
  outline: none;
}
.input2-con .input2:focus {
  border-color: #523bb8;
  outline: none;
}
.input2-con .input2:focus + ion-icon {
  color: #523bb8;
}
.coin-name {
  font-size: 14px;
  letter-spacing: 0.07em;
  color: #525866;
  font-weight: 500;
}
.coin-code {
  margin-left: auto;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #acb1bf;
}
.input3-con {
  position: relative;
}
.input3-con .input {
  padding-left: 54px;
}
.input3-con .input-icon {
  position: absolute;
  top: 12px;
  left: 10px;
}
.input3-con .input-icon::after {
  position: absolute;
  content: "";
  width: 1.4px;
  height: 51px;
  top: -6px;
  right: -7px;
  background-color: #ebeef1;
}

// Coin-View Page
.coin-view-con {
  padding: 20px 0 40px 0;
  min-height: 80vh;
  min-width: 340px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.coin-currency {
  margin: 0 0.2em 0 0 !important;
  color: #bbcbd4;
  font-size: 27px;
  letter-spacing: 0.05em;
  margin: 0 0 10px;
  font-weight: 500;
}
.coin-price {
  // color: #565b68;
  font-size: 27px;
  letter-spacing: 0.05em;
  margin: 0 0 10px;
  font-weight: 500;
}
.coin-profit {
  font-size: 13px;
  font-weight: 700;
  margin: 0 0 10px;
}
.coin-detail-con {
  display: flex;
  justify-content: center;
  border-top: 1px solid #dde6eb;
  min-height: 100px;
}
.coin-detail {
  padding: 25px 15px;
  max-width: 240px;
  flex: 1;
}
.coin-detail.border-left {
  border-left: 1px solid #dde6eb;
}
.coin-detail-title {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  display: block;
  margin-bottom: 4px;
}
.coin-detail-value {
  color: #565b68;
  font-size: 15px;
  line-height: 1.4;
  letter-spacing: 0.05em;
  font-weight: 500;
  white-space: break-spaces;
}
.coin-detail-value-grey {
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #acb1bf;
  vertical-align: middle;
  margin: 0 0 0 0.2em;
}
.coin-description {
  max-width: 500px;
  flex: 1;
}
// Notifications Modal CSS
.notifications-modal {
  max-width: 420px;
}
.notifications-modal .notification-con {
  padding: 10px 10px 10px 0;
  border-bottom: 1px solid #bdbdbd;
  cursor: pointer;
}
.notification-con.unread {
  background: #f3f3f3;
}
.notifications-modal .notification-bell-icon {
  font-size: 21px;
  border: 2px solid #525252;
  border-radius: 50px;
  padding: 6px 7px 7px 6px;
  color: #2f2f2f;
  margin: 4px 0 0 0;
}
.notifications-modal .notification-read-icon {
  font-size: 20px;
  margin: 11px 0 0 0;
}
.notifications-modal .notification-text {
  font-size: 14px;
  text-transform: lowercase;
}
.notifications-modal .notification-text::first-letter {
  text-transform: uppercase;
}
.notification-badge-con {
  position: relative;
}
.notification-badge {
  position: absolute;
  top: 1px;
  right: -4px;
  font-size: 10px;
  padding: 3px 4px 2px 6px;
  background-color: #ff6868;
  color: white;
  border-radius: 50px;
  z-index: 100000;
}
// **************************************************************************
// Stocks Investment Components CSS - Starts
// **************************************************************************
.stock-symbol {
  background: #c1c1c1;
  padding: 2px 9px;
  font-size: 0.9rem;
  color: white;
  font-weight: 700;
  border-radius: 4px;
  min-width: 90px;
  text-align: center;
}
.stock-value {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #232323;
  font-size: 0.85rem;
  font-weight: 500;

  & span {
    &:first-child {
      color: grey;
    }

    &:last-child {
      font-size: 0.95rem;
      font-weight: 600;
    }
  }
}
// **************************************************************************
// Stocks Investment Components CSS - Ends
// **************************************************************************

// **************************************************************************
// Responsive CSS
// **************************************************************************
@media (min-width: 1201px) {
  .coin-icons-list-ml {
    transform: translateX(68px);
    -webkit-transform: translateX(68px);
    -moz-transform: translateX(68px);
    -ms-transform: translateX(68px);
    -o-transform: translateX(68px);
  }
  .coin-icons-list-mr {
    transform: translateX(-30px);
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    -o-transform: translateX(-30px);
  }
  .supported-coins {
    transform: translate(0px, -20px);
    -webkit-transform: translate(0px, -20px);
    -moz-transform: translate(0px, -20px);
    -ms-transform: translate(0px, -20px);
    -o-transform: translate(0px, -20px);
  }
  .form-modal {
    max-width: 450px;
  }
}
@media (max-width: 1200px) {
  .hide-below-1200 {
    display: none;
  }
  .supported-coins-con {
    display: inline-block;
  }
}
@media (min-width: 993px) {
  // Manual Saving Update Reminder Modal CSS
  .msu-reminder-modal {
    background: rgba(0, 0, 0, 0.5) !important;
    padding: 34vh 19vw 0 19vw !important;
  }
}
@media (max-width: 992px) {
  .p-ml {
    margin-left: 10px;
  }
  .custom-modal {
    max-width: 78%;
  }
  .custom-modal.m-l-md {
    margin-left: 17%;
  }
}
@media (max-height: 750px), (max-width: 1200px) {
  .phone {
    width: 240px;
    height: 455px;
  }
  .section2-left {
    padding-left: 30%;
  }
  .h3 {
    font-size: 32px;
  }
  .h4 {
    font-size: 20px;
    margin: 10px 0;
  }
  .text1 {
    font-size: 18px;
  }
  .text3 {
    font-size: 14px;
    margin: 10px 0;
  }
}
// sizeMd Release  ->  sizeSm  starts
@media (max-width: 767px) {
  .hero-bg-1 {
    background-size: 200% 100%;
  }
  .section2 {
    padding: 100px 0;
  }
  .section4 {
    padding: 110px 0;
  }
  footer {
    padding: 0 30px 130px;
  }
}
// sizeSm release  ->  size  starts
@media (max-width: 575px) {
  .section2-left,
  .section2-right {
    padding-left: 36%;
  }
  .supported-coins {
    background-size: 100% 100%;
    width: 310px;
    height: 310px;
    margin: 0;
  }

  .custom-modal.m-l-md {
    margin-left: 27%;
    margin-top: 40px;

    &.notifications-modal {
      margin-left: 50%;
      min-width: 300px;
    }
  }
}
